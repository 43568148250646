import React, { Component } from "react";

class Demo extends Component {
    render() {
        return (
            <div>
                <span className="text-uppercase page-subtitle">
                    Dashboard
                </span>
                <h3 className="page-title">Dashboard</h3>
            </div>
            // <div className="page-header py-4 no-gutters row">
            //     <div className="text-sm-left mb-3 text-center text-md-left mb-sm-0 col-12 col-sm-4">
            //         <span className="text-uppercase page-subtitle">
            //             Bienvenido
            //         </span>
            //         <h3 className="page-title">Este es tu Dashboard</h3>
            //     </div>
            // </div>
        );
    }
}

export default Demo;
