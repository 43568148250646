import { api } from "../../../utility/api";

export const getPropiedades = (search, nombre, filtrar = false) => {
    let datos = [];
    if (filtrar) {
        datos.push({ label: "Todos", value: "" });
        search = { search };
    } else {
        search = { search, activo: true };
    }
    console.log("este es seeeee", search, "nombre: ", nombre);

    // console.log("dentro de getPropiedades #2", search);
    // console.log("dentro de getPropiedades #nombre", nombre);
    return api
        .get(nombre, search)
        .then((response) => {
            if (response) {
                response.results.forEach((grupo) => {
                    datos.push({
                        value: grupo.id,
                        label: grupo.nombre,
                    });
                });
            }

            return datos;
        })
        .catch(() => {
            return [];
        });
};


export const getClientes = (search, nombre, proyecto) => {
    let clientes = [];
    
    console.log("Buscando los clientes del proyecto:")
        const data = {
        search,
        proyecto
    }
    
    return api
        .get(nombre, data)
        .then((response) => {
            if (response) {
                response.results.forEach((grupo) => {
                    clientes.push({
                        value: grupo.id,
                        label: grupo.nombre,
                    });
                });
            }
            console.log("Clientes Select: ", clientes);
            return clientes;
        })
        .catch(() => {
            return [];
        });
};

export const getProgramadorDiseñador = (search, nombre, proyecto) => {
    let datos = [];
    const data = {
        search,
        proyecto
    }
    // console.log("dentro de getPropiedades #2", search);
    // console.log("dentro de getPropiedades #nombre", nombre);
    return api
        .get(nombre, data)
        .then((response) => {
            if (response) {
                response.results.forEach((grupo) => {
                    datos.push({
                        value: grupo.id,
                        label: grupo.nombre,
                    });
                });
            }

            return datos;
        })
        .catch(() => {
            return [];
        });
};

export const getAgente = (search) => {
    let agentes = [{ label: "Todos", value: "" }];
    return api
        .get("user", { search })
        .then((response) => {
            if (response) {
                response.results.forEach((agente) => {
                    agentes.push({
                        value: agente.id,
                        label: agente.first_name,
                    });
                });
            }

            return agentes;
        })
        .catch(() => {
            return [];
        });
};



export const getEstadosCliente = (search,filtrar = false) => {
    let estados = [];
    if (filtrar) {
        estados.push({ label: "Todos", value: "" });
        search = { search };
    } else {
        search = { search };
    }
    return api
        .get("ticket/estados", search)
        .then((response) => {
            if (response) {
                response.results.forEach((Res) => {
                    
                    if(Res.nombre.toLowerCase() == "cerrado" || Res.nombre.toLowerCase() =="abierto"){
                    estados.push({ value: Res.id, label: Res.nombre });
                    console.log("Estados",{"Res.id": Res.id, "Res.nombre": Res.nombre })
                    }
                });
            }
            console.log(estados);
            return estados;
        })
        .catch(() => {
            return [];
        });
};



export const getProjectsCliente = (search,filtrar=false) => {
    let proyectos = [];
    if (filtrar) {
        proyectos.push({ label: "Todos", value: "" });
        search = { search };
    } else {
        search = { search };
    }
    return api
        .get("proyecto/proyectosClientes",  search )
        .then((response) => {
            if (response) {
                console.log("ResponsePC", response)
                response.results.forEach((Res) => {
                    // if(Res.nombre == "Cerrado" || Res.nombre =="Abierto"){
                    proyectos.push({ value: Res.id, label: Res.nombre });
                    console.log("ProyectosCliente",{"Res.id": Res.id, "Res.nombre": Res.nombre })
                    // }
                });
            }
            console.log(proyectos);
            return proyectos;
        })
        .catch(() => {
            return [];
        });
};