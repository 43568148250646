import React, { Component } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import './navbar.css';
const defaultAvatar = require("assets/img/avatar-placeholder.png");

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { dropdownOpen: false };
    }

    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };
    render() {
        const { navToggle, logOut, user, me } = this.props;

        return (
            <nav className="align-items-stretch flex-md-nowrap p-0 navbar navbar-light">
                <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
                    <div className="ml-3 input-group input-group-seamless navar" />
                </div>
                <ul className="border-left flex-row navbar-nav">
                    <Dropdown
                        isOpen={ this.state.dropdownOpen }
                        toggle={ this.toggle }
                    >
                        <DropdownToggle
                            color="light"
                            caret
                            className="dropdown"
                        >
                            <img
                                className="user-avatar rounded-circle mr-3"
                                src={
                                    user.profile && user.profile.avatar
                                        ? user.profile.avatar
                                        : defaultAvatar
                                }
                                alt="User Avatar"
                            />
                            <span className="d-none d-md-inline-block">
                                {user.first_name}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu className="navbar-profile">
                            {/* <DropdownItem header>Header</DropdownItem> */}
                            {/* <DropdownItem>
                                <Link tabIndex="0" to="/user-profile">
                                    <i className="material-icons"></i>
                                    Profile
                                </Link>
                            </DropdownItem> */}                        
                            <DropdownItem>
                                {user.rol &&
                                (user.rol.nombre == "Cliente") ? (
                                    <Link tabIndex="0" to="/resetpassCliente">
                                        <i className="material-icons"></i>
                                        Cambiar contraseña
                                    </Link>
                                ) : (
                                    <Link tabIndex="0" to="/resetpass">
                                        <i className="material-icons"></i>
                                        Cambiar contraseña
                                    </Link>
                                )}
                            </DropdownItem>

                            <DropdownItem divider />
                            <DropdownItem>
                                <a
                                    tabIndex="0"
                                    className="text-danger"
                                    onClick={logOut}
                                    href="/"
                                >
                                    <i className="material-icons text-danger">
                                        
                                    </i>
                                    Cerrar Sesión
                                </a>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </ul>
                <nav className="nav">
                    <a
                        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
                        onClick={navToggle}
                    >
                        <i className="material-icons"></i>
                    </a>
                </nav>
            </nav>
        );
    }
}

export default Navbar;
